<template>
  <div class="article">
    <template v-if="isMobile">
      <div class="mobile-article-header">
        <div class="mobile-article-head-txt">
          <div class="title">iLaw&nbsp;·&nbsp;{{ $route.query.type === "1" ? "实务文章" : "直播" }}</div>
          <div class="desc">
            <template v-if="$route.query.type === '1'">
              <div v-for="item of bannerList" :key="item.id">
                <div class="item">
                  <div class="sub-title">{{ item.title }}</div>
                  <div class="sub-detail article-sub-detail">{{ item.detail }}</div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="item">
                <div class="sub-detail live-sub-detail">{{ "汇聚行业大咖，聚焦合规实务分享" }}</div>
              </div>
            </template>
          </div>
        </div>
        <img v-if="$route.query.type === '1'" src="@/images/newHome/resourceCentre/mobile-article-banner.png" alt="" />
        <img v-else src="@/images/newHome/resourceCentre/mobile-live-banner.png" alt="">
      </div>
    </template>
    <div class="article-header" v-else>
      <img src="@/images/newHome/resourceCentre/article-banner.png" alt="" />
      <div class="article-header-box">
        <div>
          <h1 class="banner-text">
            {{ $route.query.type === "1" ? "iLaw·实务文章" : "iLaw·直播" }}
          </h1>
          <div class="banner-tips" v-if="$route.query.type === '1'">
            <div class="banner-con" v-for="item of bannerList" :key="item.id">
              <div class="tag-box">
                <div class="sub-title">{{ item.title }}</div>
                <div class="sub-detail">{{ item.detail }}</div>
              </div>
            </div>
          </div>
          <div class="banner-tips" v-else>
            <div class="live-broadcast-title">
              {{ "汇聚行业大咖，聚焦合规实务分享" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="article-main">
      <h1 class="title">
        {{ $route.query.type === "1" ? "iLaw文章" : "iLaw直播" }}
      </h1>
      <div class="main-con">
        <div class="left-article">
          <div class="article-list" v-loading="loading">
            <template v-if="$route.query.type === '1'">
              <template v-if="isMobile">
                <MobileArticleList :articleList="articleList"></MobileArticleList>
              </template>
              <template v-else>
                <div class="article-item" v-for="item of articleList" :key="item.id">
                  <div class="iframeBG" v-html="ImgFun(item.displayCover, 'bg')"></div>
                  <template v-if="item.jumpLink">
                    <a class="left-con" :href="item.jumpLink" target="_blank">
                      <h2 class="name">
                        {{ item.title }}
                      </h2>
                      <div class="left-con-bottom">
                        <div class="date">
                          {{ item.articleUpdateTime.split(" ")[0] }}
                        </div>
                      </div>
                    </a>
                  </template>
                  <template v-if="!item.jumpLink">
                    <div class="left-con" @click="openLink(item.link)">
                      <h2 class="name">
                        {{ item.title }}
                      </h2>
                      <div class="left-con-bottom">
                        <div class="date">
                          {{ item.articleUpdateTime.split(" ")[0] }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </template>
            <template v-else>
              <LiveBroadcast v-if="isMobile" :videList="articleList"></LiveBroadcast>
              <template v-else>
                <div class="article-item" v-for="item of articleList" :key="item.id" @click="openLink(item.link)">
                  <div class="live-broadcast" v-html="ImgBroadcast(item.cover, 'bg')"></div>
                  <div class="left-con">
                    <h2 class="name">
                      {{ item.title }}
                    </h2>
                    <div class="left-con-bottom">
                      <div class="date">
                        {{ item.articleUpdateTime.split(" ")[0] }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </div>
          <div class="pagination">
            <el-pagination style="text-align: center" :background="!isMobile" :current-page.sync="currentPage"
              :page-size="15" :pager-count="isMobile ? 5 : 7" layout="prev, pager, next" :total="total"
              @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
        <div class="btn-us-contact">
          <img src="../../images/newHome/resourceCentre/qr-code.png" alt="iLaw联系方式" />
          <div class="btn-us-contact-title">扫码联系圈圈，了解更多</div>
        </div>
      </div>
    </div>
    <MobileFooter v-if="isMobile"></MobileFooter>
    <footerCom v-else></footerCom>
  </div>
</template>

<style lang="scss" scoped>
@import "./index.scss";
@import "@/pages/css/mobile-base.scss";

@media(max-width: $MobileWidth) {
  @import "./mobile-article.scss";
}
</style>

<script>
import MobileArticleList from "@/components/mobile-article-list/index";
import LiveBroadcast from "@/components/live-broadcast/index";
import footerCom from "../../components/footerCom/index";
import MobileFooter from "@/components/mobile-footer/index";
import api from "../../api/request";
import { mapState } from "vuex";
export default {
  name: "articleIndex",
  components: { footerCom, MobileArticleList, LiveBroadcast, MobileFooter },
  computed: {
    ...mapState(["isMobile"]),
  },
  mounted() {
    if (this.$route.query.type === "1") {
      this.getArticles();
    } else {
      this.getSupplement();
    }
  },
  data() {
    return {
      bannerList: [
        {
          id: 1,
          title: "11w+",
          detail: "法律行业粉丝",
        },
        {
          id: 2,
          title: "1150+",
          detail: "行业优质文章",
        },
        {
          id: 3,
          title: "350+",
          detail: "法律人创作投稿",
        },
      ],
      currentPage: 1,
      total: 0,
      articleList: [],
      loading: false,
    };
  },
  methods: {
    ImgBroadcast(url, type) {
      let styles;
      let img;
      if (type === "bg") {
        img = "imgBg";
        styles = "width:120px;height:120px;position:absolute;top:0;left:0;";
      }
      var randomId = img + url;
      window[`${img}${url}`] =
        '<img id="img" style=\'' +
        styles +
        "' src='" +
        url +
        "?" +
        "img" +
        "'/><script>window.onload = function() { parent.document.getElementById('" +
        randomId +
        "').height = 100+'%'; } <" +
        "/script>";
      var iframeStr = `<iframe id=${randomId} src="javascript:parent['${img}${url}']" frameBorder="0" scrolling="no" width="100%"></iframe>`;
      return iframeStr;
    },
    ImgFun(url, type) {
      let styles;
      let img;
      if (type === "bg") {
        img = "imgBg";
        styles = "width:250px;position:absolute;top:0;left:0;";
      }
      var randomId = img + url;
      window[`${img}${url}`] =
        '<img id="img" style=\'' +
        styles +
        "' src='" +
        url +
        "?" +
        "img" +
        "'/><script>window.onload = function() { parent.document.getElementById('" +
        randomId +
        "').height = 100+'%'; } <" +
        "/script>";
      var iframeStr = `<iframe id=${randomId} src="javascript:parent['${img}${url}']" frameBorder="0" scrolling="no" width="100%"></iframe>`;
      return iframeStr;
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.$route.query.type === "1") {
        this.getArticles();
      } else {
        this.getSupplement();
      }
    },

    async getArticles() {
      this.loading = true;
      const params = {
        pageNum: this.currentPage,
        pageSize: 15,
      };
      const { code, msg, data } = await api.getArticles(params);
      if (code === 0) {
        console.log(data);
        this.articleList = data.list || [];
        this.total = data.total || 0;
      } else {
        this.$message.error(msg);
      }
      this.loading = false;
    },

    async getSupplement() {
      this.loading = true;
      const params = {
        pageNum: this.currentPage,
        pageSize: 15,
      };
      const { code, msg, data } = await api.getSupplement(params);
      if (code === 0) {
        console.log(data);
        this.articleList = data.list || [];
        this.total = data.total || 0;
      } else {
        this.$message.error(msg);
      }
      this.loading = false;
    },

    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
